/* src/Styles/Footer.css */
.footer {
  background-color: #1f2937;
  padding: 15px 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.footer-container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.footer-logo img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.footer-logo span {
  color: white;
  font-size: 1.5em;
  font-weight: 100;
  font-family: 'Anton', sans-serif; /* Apply the Anton font */
}

.footer-nav {
  display: flex;
  align-items: center;
}

.footer-nav a {
  color: #adb5bd;
  margin: 0 10px;
  text-decoration: none;
}

.footer-nav a:hover {
  color: white;
}
