/* src/Styles/ProductItem.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.product-item {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 24px rgba(0, 0, 0, 0.2);
}

.product-badge {
  position: absolute;
  top: 58px;
  left: -32px;
  background-color: #ff6f61;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 700;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: rotate(-30deg);
  transform-origin: top left;
  display: flex;
  align-items: center;
}

.product-badge.best-choice {
  background-color: #ff6f61;
}

.product-badge.best-value {
  background-color: #28a745;
}

.product-badge::before {
  content: '\f091';
  font-family: 'FontAwesome';
  margin-right: 10px;
}

.product-value {
  position: absolute;
  top: 51px;
  left: -29px;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 700;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: rotate(-30deg);
  transform-origin: top left;
  display: flex;
  align-items: center;
}

.product-value::before {
  content: '\f155'; /* Ícone de dólar do FontAwesome */
  font-family: 'FontAwesome';
  margin-right: 10px;
}

.product-rank {
  font-size: 1.5em;
  color: #6c757d;
  margin-right: 20px;
}

.product-image {
  text-align: center;
  margin-right: 40px;
}

.product-image img {
  max-width: 100px;
  border-radius: 5px;
}

.product-image p {
  color: #5e5e5e;
}

.product-details {
  flex: 2;
  text-align: left;
}

.product-details h2 {
  margin: 0 0 10px 0;
  font-size: 1.5em;
  font-weight: 600;
  color: #4a4a4a;
}

.free-shipping {
  color: #007bff;
  text-decoration: none;
  margin: 10px 0;
  display: inline-block;
  font-weight: 500;
}

.show-more {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}

.product-details p {
  margin: 5px 0;
  font-size: 1em;
  line-height: 1.5;
  color: #555;
}

.detailed-description {
  margin: 10px 0 0 0;
  padding-left: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.detailed-description.expanded {
  max-height: 800px; /* Ajuste conforme necessário */
  opacity: 1;
}

.reviews {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.reviews.expanded {
  opacity: 1;
}


.detailed-description li {
  margin-bottom: 5px;
  color: #555;
}

.product-rating {
  text-align: center;
}

.product-rating p {
  color: #5e5e5e;
}

.rating-value {
  font-size: 2em;
  color: #007bff;
  font-weight: 500;
}

.rating-text {
  color: #6c757d;
  font-size: 0.9em;
}

.stars {
  color: #ffc107; /* Cor amarela para as estrelas */
  margin: 5px 0;
}

.stars i {
  margin-right: 2px; /* Espaçamento entre as estrelas */
}

.view-deal {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

.view-deal:hover {
  background-color: #0056b3;
}

.reviews {
  margin-top: 20px;
}

.reviews h3 {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.total-ratings {
  margin-left: 10px;
  font-size: 0.7em;
  color: #007bff;
}

.reviews ul {
  list-style-type: none;
  padding: 0;
}

.reviews ul li {
  border-bottom: 1px solid #dee2e6;
  padding: 10px 0;
}

.reviews ul li p {
  margin: 5px 0;
}
