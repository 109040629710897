/* src/Styles/Header.css */
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.header {
  background-color: #1f2937;
  padding: 15px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 51px;
  height: 57px;
  margin-right: 15px;
}

.logo span {
  color: white;
  font-size: 1.8em;
  font-weight: 100;
  font-family: 'Anton', sans-serif; /* Apply the Anton font */
}

.header-nav {
  display: flex;
  align-items: center;
}

.header-nav span {
  color: white;
  font-size: 1.2em;
  margin-right: 15px;
}

.flag-icon {
  display: flex;
  align-items: center;
}

.flag-icon img {
  width: 35px;
  height: auto;
  margin-left: 5px;
  border-radius: 50%;
}

.logo .logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}