/* src/Styles/App.css */
body {
  background-color: #f8f9fa;
}

.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

a {
  text-decoration: none;
}