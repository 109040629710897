/* src/Styles/AboutUs.css */
.about-us {
    background-color: #f8f9fa;
    padding: 50px 0;
  }
  
  .about-us-container {
    width: 70%;
    margin: 0 auto;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-us-logo {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .about-us h1 {
    font-size: 2em;
    font-family: 'Anton', sans-serif;
    color: #333;
    font-weight: 100;
  }
  
  .about-us-content p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
    margin: 10px 0;
  }
  