@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.product-list {
  padding: 20px;
  width: 70%;
  margin: 0 auto;
}

.product-list-title {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  margin: 20px 0;
  color: #333;
  font-weight: 700;
}